import React, { useContext, useEffect, useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, Chip, IconButton, LinearProgress } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ReplayIcon from '@mui/icons-material/Replay';
import { UserContext } from '../components/UserContext';

export default function UserInfoCard({ userId }) {

  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const { adminMode } = useContext(UserContext);

  const id = userId != null ? userId : "me";

  const labels = (user == null || user.groups == null) ? [] : user.groups.map(
    group => 
      <Chip key={group.id} size="small" sx={{ marginRight: 1 }} label={group.name} color="primary" variant="outlined" />
  );

  useEffect(() => {
    fetch(process.env.REACT_APP_API_DOMAIN + "/people/users/"+id+"/", {
      method:'GET', 
      credentials: 'include' 
     })
     .then(res => {
        if (!res.ok) {
          if ([401, 403].includes(res.status)) {
            throw Error('Not Authenticated');
          } else {
            throw Error(res.statusText);
          }
        }
        return res.json()
      })
      .then(
        (result) => {
          setUser(result);
        },
        (error) => {
          console.log(error.message);
          setError(error.message);
        }
      );
  }, [id, error]);

  const resetCard = () => {
    setUser(null);
    setError(null);
//    setEditing(false);
  }

  // RENDER
  if (error != null) {
    // ERROR
    return (
      <Card>
        <CardHeader title={<Typography variant="h5" color="primary">Usuari</Typography>}></CardHeader>
        <CardContent><Typography variant="body1">ERROR {error}</Typography></CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <IconButton color="primary" aria-label="recarregar" onClick={resetCard}>
            <ReplayIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  } else if (user == null) {
    // LOADING
    return (
      <Card>
        <CardHeader title={<Typography variant="h5" color="primary">Usuari</Typography>}></CardHeader>
        <CardContent><Typography variant="body1">Carregant dades...</Typography><LinearProgress /></CardContent>
      </Card>
    );
  } else {
    // VISUALITZACIO
    return (
      <Card>
        <CardHeader title={<Typography variant="h5" color="primary">Usuari</Typography>}></CardHeader>
        <CardContent>
          <List>
            <ListItem alignItems="flex-start" divider>
              <ListItemText
                primary={<Typography variant="subtitle2" color="text.secondary">Correu electrònic</Typography>}
                secondary={<Typography variant="body1" color="text.primary">{ user.email }</Typography>}
              />
            </ListItem>
            { adminMode && (
              <>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography fontSize={"0.8rem"} variant="subtitle2" color="text.secondary">Id d'usuari</Typography>}
                    secondary={<Typography fontSize={"0.8rem"} variant="body1" color="text.primary">{ user.id }</Typography>}
                  />
                </ListItem>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography fontSize={"0.8rem"} variant="subtitle2" color="text.secondary">Usuari</Typography>}
                    secondary={<Typography fontSize={"0.8rem"} variant="body1" color="text.primary">{ user.username }</Typography>}
                  />
                </ListItem>
              </>
            )}
            <ListItem alignItems="flex-start">
              <ListItemText>{ labels }</ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    );
  }

};
