import dayjs from 'dayjs';

export const defaultInternalDateFormat = (d) => {

    if (d == null) {
        return null;
    }

    let parsedDate = dayjs(d);

    if (parsedDate.isNaN) {
        return null;
    }

    return parsedDate.format('YYYY-MM-DD');
}

export const defaultDisplayDateFormat = (d) => { 
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    //console.log(d);
    if (d == null) {
        return null;
    }
    let parsedDate = new Date(d);
    if (isNaN(parsedDate.valueOf())) {
        return null;
    }
    return new Intl.DateTimeFormat('ca-ES', options).format(parsedDate);
}


export const getCookie = (name) => {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
