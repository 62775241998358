import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import { Card, CardActions, CardContent, IconButton } from '@mui/material';
import QrReader from 'modern-react-qr-reader';
import React, { useState } from 'react';
import BadgeValidationResultCard from '../cards/MemberBadgeValidationCard';

const QRScanner = () => {

  const [result, setResult] = useState(null); //"38ea67b7-00aa-4042-86b6-8c47f01070f5"); //null);
  const [frontCam, setFrontCam] = useState(false);

  const handleScan = (data) => {
    if (data != null) {
      setResult(data);
    }
  }

  const handleError = (error) => {
    console.log(error);
  }

  const resetScan = () => {
    setResult(null);
  }

  const flipCam = () => {
    setFrontCam(!frontCam);
  }


  if (result == null) {
    return(
      <Card>
        <CardContent>
          <QrReader
              delay={500}
              facingMode={ frontCam ? 'user' : 'environment'}
              onError={handleError}
              onScan={handleScan}
              style={{ height: 320, width: 320, }}
            />
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <IconButton color="primary" aria-label="recarregar" onClick={flipCam}>
            <CameraswitchIcon />
          </IconButton>
        </CardActions>
      </Card>
    )
  } else {
    return (
      <BadgeValidationResultCard badge_id={result} resetScan={resetScan} />
    )
  }

}

export default QRScanner;