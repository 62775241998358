import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import PersonInfoCard from '../cards/PersonInfoCard';
//import UserInfo from '../components/UserInfo';

export default function PersonInfoPage() {

  const { personId } = useParams();
  const id = personId != null ? personId : "me";

  return (
    <DefaultLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PersonInfoCard personId={id} />
        </Grid>
      </Grid>
    </DefaultLayout>
  );

};
