import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import QRShowPage from './pages/QRShowPage';
import QRReaderPage from './pages/QRReaderPage';
import MemberBadgePage from './pages/MemberBadgePage';
import UserInfoPage from './pages/UserInfoPage';
import PersonInfoPage from './pages/PersonInfoPage';
import MemberBadgeListPage from './pages/MemberBadgeListPage';
import HomeIcon from '@mui/icons-material/Home';
import LoginIcon from '@mui/icons-material/Login';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UserInfoListPage from './pages/UserInfoListPage';
import MemberInfoPage from './pages/MemberInfoPage';
import MemberInfoListPage from './pages/MemberInfoListPage';
import AnonHomePage from './pages/AnonHomePage';
import PersonInfoListPage from './pages/PersonInfoListPage';
import ProfilePage from './pages/ProfilePage';

export const routes = {
  'qrShow': { path: '/show', displayName: 'Mostrar QR', element: <QRShowPage/>, icon: <QrCode2Icon/>},
  'qrReader' : { path: '/reader', displayName: 'Llegir QR', element: <QRReaderPage/>, icon: <QrCodeScannerIcon/>},
  'badgeList' : { path: '/badges', displayName: 'Llista carnets', element: <MemberBadgeListPage/>, icon: <BadgeIcon/>},
  'badgeDetail' : { path: '/badges/:badgeId', displayName: 'Detall carnet', element: <MemberBadgePage/>, icon: <BadgeIcon/>},
  'myBadge' : { path: '/badges/mine', displayName: 'El meu carnet', element: <MemberBadgePage/>, icon: <BadgeIcon/>},
  'profile' : { path: '/profile', displayName: 'Dades bàsiques', element: <ProfilePage/>, icon: <AccountCircleIcon/>},
  'userDetail' : { path: '/users/:userId', displayName: 'Dades bàsiques usuari', element: <UserInfoPage/>, icon: <AccountCircleIcon/>},
  'userList' : { path: '/users', displayName: 'Llista d\'usuaris', element: <UserInfoListPage/>, icon: <BadgeIcon/>},
  'personDetail' : { path: '/people/:personId', displayName: 'Dades bàsiques persona', element: <PersonInfoPage/>, icon: <AccountCircleIcon/>},
  'personList' : { path: '/people', displayName: 'Llista de persones', element: <PersonInfoListPage/>, icon: <BadgeIcon/>},
  'myMemberDetail' : { path: '/members/me', displayName: 'Informació soci', element: <MemberInfoPage/>, icon: <BadgeIcon/>},
  'memberDetail' : { path: '/members/:memberId', displayName: 'Informació soci', element: <MemberInfoPage/>, icon: <BadgeIcon/>},
  'memberList' : { path: '/members', displayName: 'Llista socis', element: <MemberInfoListPage/>, icon: <BadgeIcon/>},
  'login' : { path: '/login', displayName: 'Login', element: <LoginPage/>, icon: <LoginIcon/>},
  'home' : { path: '/dashboard', displayName: 'Inici', element: <HomePage/>, icon: <HomeIcon/>},
  'anonHome' : { path: '/', element: <AnonHomePage/>,},
}

function AppRouter() {

  return (
    <Router>
      <Routes>
        {
          Object.entries(routes).map(
            ([key, value]) => (
              <Route exact key={key} path={ value.path } element={ value.element }/>
            )
          )
        }
      </Routes>
    </Router>
  );
}

export default AppRouter;
