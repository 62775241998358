import React, { useContext, useState } from 'react';
import { UserContext } from '../components/UserContext';
import { Navigate } from 'react-router-dom';
import { Box, Container, Grid } from '@mui/material';
import AnonUserAppBar from '../components/AnonUserAppBar';
import AnonUserAppBarMenu from '../components/AnonUserAppBarMenu';

function AnonymousUserLayout(props) {

    const { user } = useContext(UserContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    if (user != null && user.username != null && user.username !== "guest") {
        console.log("usuari ja connectat: "+JSON.stringify(user));
        return <Navigate to="/dashboard" replace />;
    } else {
        return (
            <>
                <Box sx={{ flexGrow: 1 }}>
                    <AnonUserAppBar handleMenu={handleMenu} />
                </Box>
                <AnonUserAppBarMenu anchorEl={anchorEl} handleMenuClose={handleMenuClose} />
                <main>
                    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={0} sm={1} md={2}/>
                            <Grid item xs={12} sm={10} md={8}>
                                {props.children}
                            </Grid>
                            <Grid item xs={0} sm={1} md={2}/>
                            <Grid item xs={0} sm={1} md={2}/>
                            <Grid item xs={12} sm={10} md={8}>
                            </Grid>
                            <Grid item xs={0} sm={1} md={2}/>
                        </Grid>
                    </Container>
                </main>
            </>
        )
    }
}

export default AnonymousUserLayout;