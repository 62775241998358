import React, { useState, useEffect } from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { defaultDisplayDateFormat } from '../utils/Utilities';
import { Grid } from '@mui/material';

export default function PersonInfoListPage() {
  
  const [people, setPeople] = useState([]);
 
  useEffect(() => {
    fetch(process.env.REACT_APP_API_DOMAIN + "/people/people/", {
      method:'GET',
      credentials: 'include' 
    })
    .then(res => {
      if (!res.ok) {
        if ([401, 403].includes(res.status)) {
          throw Error('Not Authenticated');
        } else {
          throw Error(res.statusText);
        }
      }
      return res.json()
    })
    .then(
      (result) => {
        setPeople(result.results);
      },
      (error) => {
        console.log(error.message);
      }
    );
  }, []);
    
  const columns = [
    { field: 'actions', type: 'actions', width: 30,
      getActions: (params) => [
        <GridActionsCellItem
        component={Link} to={'/people/'+params.row.id}
        icon={<VisibilityIcon/>} label="Veure persona"
        showInMenu={false} />,
      ]
    },
    { field: 'id', type: 'number', headerName: 'ID' },
    {
      field: 'nom', 
      headerName: "Nom",
      width: 240,
      valueGetter: (params) => {
        console.log({ params });
        let result = [];
        if (params.row.user) {
          if (params.row.user.first_name) {
            result.push(params.row.user.first_name);
          }
          if (params.row.user.last_name) {
            result.push(params.row.user.last_name);
          }
        } else {
          result = ["Unknown"];
        }
        return result.join(" ");
      }
    },
    { field: 'dni', width: 100, headerName: 'DNI' },
    { field: 'gender', headerName: 'Gènere', width: 100,
      valueFormatter: params => {
        return (params.value === 'M' ? 'Home' : params.value === 'F' ? 'Dona' : '-')
      }
    },
    { field: 'birth_date', type: 'date', headerName: 'Data Naixement', editable: false, width: 120,
      valueFormatter: params => { 
        return (params.value !== null)? defaultDisplayDateFormat(params.value) : null
      }
    },
  ]

  return (
    <DefaultLayout>
      <Grid item xs={12}>
        <DataGrid rows={people} columns={columns} autoHeight
          initialState={{
            sorting: {
              sortModel: [{ field: 'nom', sort: 'asc' }],
            },
          }}
        />
      </Grid>
    </DefaultLayout>
  );
};
