import { Card, CardContent, CardHeader, LinearProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function LoadingCard({ cardTitle }) {

  return (
    <Card>
      { cardTitle && <CardHeader title={<Typography variant="h5" color="primary">{ cardTitle }</Typography>}></CardHeader> }
      <CardContent>
        <Typography variant="body1">Carregant dades...</Typography>
        <LinearProgress />
      </CardContent>
    </Card>
  );

};
