import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { MenuList } from '@mui/material';

export default function ConnectedUserAppBarMenu(props) {

  return (
    <Menu
      id="menu-appbar"
      anchorEl={props.anchorEl}
      anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
      }}
      open={Boolean(props.anchorEl)}
      onClose={props.handleMenuClose}
    >
      <MenuList sx={{ width: 200 }}>
        <MenuItem component="a" href={process.env.REACT_APP_API_DOMAIN + "/api-auth/logout/"}>
          <ListItemText primary='Logout'/>
          <ListItemIcon>
            <LogoutIcon/>
          </ListItemIcon>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
