import React from 'react';
import { Button, Card, CardActions, CardContent, Skeleton, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

export default function MemberBadgeCard({badge}) {
  
  if (badge != null) { 
    return (
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6" color="text.secondary" gutterBottom>
              { badge.member.member_number }
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h5" component="div">
              { badge.member.person.user.first_name + ' ' + badge.member.person.user.last_name }
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2, mb: 2}}>
            { badge.id ? (
                <QRCode value={badge.signed_id} />
              ) : (
                <Skeleton variant="rectangular" width={256} height={256}></Skeleton>
              )
            }
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography sx={{ mb: 1.5 }} color="text.secondary" variant={'caption'}>
              { badge.id }
            </Typography>
          </Box>
        </CardContent>
        <CardActions>
            <Button size="small" component={Link} to={ "/members/" + badge.member.id}>Learn More</Button>
        </CardActions>
      </Card>
    )
  } else {
    return (
      <Skeleton>
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2, mb: 2}}>
              <Skeleton variant="rectangular" width={256} height={256}></Skeleton>
            </Box>
          </CardContent>
        </Card>
      </Skeleton>
    )
  }
};
