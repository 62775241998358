import React, { useState, useEffect } from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import MemberBadgeCard from '../cards/MemberBadgeCard';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function MemberBadgePage() {

  const [badge, setBadge] = useState(null);
  const [error, setError] = useState(null);

  const { badgeId } = useParams();
  let id = badgeId != null ? badgeId : "mine";

  useEffect(() => {
    fetch(process.env.REACT_APP_API_DOMAIN + "/members/badges/"+id+"/", {
      method:'GET',
      credentials: 'include'
    })
    .then(res => {
      if (!res.ok) {
        if ([401, 403].includes(res.status)) {
          throw Error('Not Authenticated');
        } else if ([401, 403].includes(res.status)) {
            throw Error('Not Found');
        } else {
          throw Error(res.statusText);
        }
      }
      return res.json()
    })
    .then(
      (result) => {
        setBadge(result);
      },
      (error) => {
        setError(error.message);
      }
    );
  }, [id]);

  return (
    <DefaultLayout hideToTopButton>
      {
        badge != null ?
        (
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8} lg={8}>
              {
                error == null?
                  <MemberBadgeCard
                    key={badge.id}
                    badge={badge}
                  /> :
                  <p>{error}</p>
              }
            </Grid>
          </Grid>
        ) : (
          <div>Carregant dades...</div>
        )
      }
    </DefaultLayout>
  );

};
