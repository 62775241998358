import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LoginIcon from '@mui/icons-material/Login';
import { MenuList } from '@mui/material';
import { Link } from 'react-router-dom';

export default function AnonUserAppBarMenu(props) {

  return (
    <Menu
      id="menu-appbar"
      anchorEl={props.anchorEl}
      anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
      }}
      open={Boolean(props.anchorEl)}
      onClose={props.handleMenuClose}
    >
      <MenuList sx={{ width: 200 }}>
        <MenuItem component={Link} to="/login">
          <ListItemText primary='Login'/>
          <ListItemIcon>
            <LoginIcon/>
          </ListItemIcon>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
