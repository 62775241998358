import { Card, CardContent, CardHeader } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { memberService } from '../services/MemberService';
import { defaultDisplayDateFormat } from '../utils/Utilities';
import ErrorCard from './ErrorCard';
import LoadingCard from './LoadingCard';
import { UserContext } from '../components/UserContext';

export default function MemberInfoCard({ memberId = "me" }) {

  const [member, setMember] = useState(null);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState("loading");

  const { adminMode } = useContext(UserContext);

  useEffect(() => {
    memberService.getById(memberId)
      .then(
        (result) => {
          setMember(result);
          if (mode === "loading") {
            setMode("view");
          }
        },
        (error) => {
          setError(error);
          setMode("error");
        }
      );
  }, [memberId, mode]);

  const resetCard = () => {
    setMember(null);
    setError(null);
    setMode("loading");
  }

  // RENDER
  if (mode === "loading") {
    return (
      <LoadingCard cardTitle="Dades soci" />
    );
  } else if (mode === "error") {
    return (
      <ErrorCard cardTitle="Dades soci" errorMessage={error} resetCard={resetCard} />
    );
  } else {
    // VISUALITZACIO
    return (
      <Card>
        <CardHeader title={<Typography variant="h5" color="primary">Dades soci</Typography>}></CardHeader>
        <CardContent>
          <List>
            <ListItem alignItems="flex-start" divider>
              <ListItemText
                primary={<Typography variant="subtitle2" color="text.secondary">Número de soci</Typography>}
                secondary={<Typography variant="body1" color="text.primary">{ member.member_number }</Typography>}
              />
            </ListItem>
            <ListItem alignItems="flex-start" divider>
              <ListItemText
                primary={<Typography variant="subtitle2" color="text.secondary">Data alta</Typography>}
                secondary={<Typography variant="body1" color="text.primary">{ defaultDisplayDateFormat(member.activation_date) }</Typography>}
              />
            </ListItem>
            { (adminMode || member.deactivation_date != null) && (
              <ListItem alignItems="flex-start" divider>
                <ListItemText
                  primary={<Typography variant="subtitle2" color="text.secondary">Data baixa</Typography>}
                  secondary={<Typography variant="body1" color="text.primary">{ member.deactivation_date ? defaultDisplayDateFormat(member.deactivation_date) : "-" }</Typography>}
                />
              </ListItem>
            )}
            <ListItem alignItems="flex-start" divider>
              <ListItemText
                primary={<Typography variant="subtitle2" color="text.secondary">Tipus de soci</Typography>}
                secondary={<Typography variant="body1" color="text.primary">{ member.subscription_type_display }</Typography>}
              />
            </ListItem>
            { adminMode && member.parent_member != null && (
              <ListItem alignItems="flex-start" divider component="a" href={"/members/"+ member.parent_member.id}>
                <ListItemText
                  primary={<Typography variant="subtitle2" color="text.secondary">Soci relacionat</Typography>}
                  secondary={<Typography variant="body1" color="text.primary">{ member.parent_member.member_number }</Typography>}
                />
              </ListItem>
            )}
            { adminMode && member.children_members != null && member.children_members.map( (c, i) => {
              return (
                <ListItem alignItems="flex-start" divider component="a" href={"/members/"+ c.id}>
                  <ListItemText
                    primary={<Typography variant="subtitle2" color="text.secondary">{"Soci relacionat "+(i+1)}</Typography>}
                    secondary={<Typography variant="body1" color="text.primary">{ c.member_number }</Typography>}
                  />
                </ListItem>
              )})
            }
            { adminMode && (
              <>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography variant="subtitle2" color="text.secondary">Membre actiu</Typography>}
                    secondary={<Typography variant="body1" color="text.primary">{ member.active ? 'Sí' : 'No' }</Typography>}
                  />
                </ListItem>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography fontSize={"0.8rem"} variant="subtitle2" color="text.secondary">Id de soci</Typography>}
                    secondary={<Typography fontSize={"0.8rem"} variant="body1" color="text.primary">{ member.id }</Typography>}
                  />
                </ListItem>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography fontSize={"0.8rem"} variant="subtitle2" color="text.secondary">Data creació registre</Typography>}
                    secondary={<Typography fontSize={"0.8rem"} variant="body1" color="text.primary">{ member.created }</Typography>}
                  />
                </ListItem>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography fontSize={"0.8rem"} variant="subtitle2" color="text.secondary">Data actualització registre</Typography>}
                    secondary={<Typography fontSize={"0.8rem"} variant="body1" color="text.primary">{ member.modified }</Typography>}
                  />
                </ListItem>
              </>
            )}
          </List>
        </CardContent>
      </Card>
    );
  }

};
