import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import MemberInfoCard from '../cards/MemberInfoCard';

export default function MemberInfoPage() {

  const { memberId } = useParams();
  const id = memberId != null ? memberId : "me";

  return (
    <DefaultLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MemberInfoCard memberId={id} />
        </Grid>
      </Grid>
    </DefaultLayout>
  );

};
