import Box from '@mui/material/Box';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FormControlLabel, Switch } from '@mui/material';
import { UserContext } from './UserContext';
import { routes } from '../AppRouter';

export default function ConnectedUserDrawer(props) {

  const checkUserGroup = (user, groupName) => {
    if (user === null || user.groups == null) {
      return false;
    }
    return user.groups.some(g => g.name === groupName);
  }

  const { user, adminMode, toggleAdminMode } = React.useContext(UserContext);

  const printMenuItem = (r) => {
    return (
      <ListItem key={r} disablePadding component={Link} to={routes[r].path}>
        <ListItemButton>
          <ListItemIcon>
            {routes[r].icon}
          </ListItemIcon>
          <ListItemText primary={routes[r].displayName} />
        </ListItemButton>
      </ListItem>
    )
  }

  const drawerListTop = () => (
    <List>
      {
        ['home', 'profile'].map(r => printMenuItem(r))
      }
      {
        checkUserGroup(user, 'members') && (
          <>
            <Divider />
            {
              ['myMemberDetail', 'myBadge'].map(r => printMenuItem(r))
            }
          </>
        )
      }
      {
        adminMode && checkUserGroup(user, 'managers') && (
          <>
            <Divider />
            {
              ['qrReader', 'userList', 'personList', 'memberList', 'badgeList'].map(r => printMenuItem(r))
            }
          </>
        )
      }
    </List>
  )

  const drawerListBottom = () => (
    <List>
      {
        checkUserGroup(user, 'managers') && (
          <ListItem key='Mode Admin' disablePadding>
            <ListItemButton>
              <FormControlLabel
                label='Mode Admin'
                labelPlacement='start'
                control={
                  <Switch
                    inputProps={{ 'aria-label': 'Toggle admin mode' }}
                    checked={adminMode}
                    onChange={toggleAdminMode} />
                }
              />
            </ListItemButton>
          </ListItem>
        )
      }
    </List>
  );

  return (
    <Drawer
      anchor='left'
      open={props.drawerState}
      onClose={props.toggleDrawer(false)}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={props.toggleDrawer(false)}
        onKeyDown={props.toggleDrawer(false)}
        className="sidebar"
      >
        { drawerListTop() }
        { drawerListBottom() }
      </Box>   
    </Drawer>
  );
}
