import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import { Card, CardActions, CardContent, CardHeader, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { peopleService } from '../services/PeopleService';
import { defaultDisplayDateFormat, defaultInternalDateFormat } from '../utils/Utilities';
import ErrorCard from './ErrorCard';
import LoadingCard from './LoadingCard';
import { UserContext } from '../components/UserContext';

export default function PersonInfoCard({ personId = "me" }) {

  const [person, setPerson] = useState(null);
  const [updatedPerson, setUpdatedPerson] = useState(null);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState("loading");

  const { user, adminMode } = useContext(UserContext);

  useEffect(() => {
    peopleService.getById(personId)
      .then(
        (result) => {
          setPerson(result);
          setUpdatedPerson(result);
          if (mode === "loading") {
            setMode("view");
          }
        },
        (error) => {
          console.log(error);
          if(error === 'Not Found') {
            setUpdatedPerson(
              {
                first_name: user.first_name,
                last_name: user.last_name,
                gender: 'U',
                dni: ''
              }
            );
            setMode("create");
          } else {
            setError(error);
            setMode("error");
          }
        }
      );
  }, [personId, mode, user]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedPerson({ ...updatedPerson, [name]: value });
  }

  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    peopleService
      .update(personId, updatedPerson)
      .then(
        (result) => {
          setPerson(result);
          setUpdatedPerson(result);
          setMode("view");
        },
        (error) => {
          console.log(error.message);
          setError(error.message);
          setMode("error");
        }
      );
  }

  const handleCreateSubmit = (event) => {
    event.preventDefault();
    peopleService
      .create({ ...updatedPerson, user_id: user.id})
      .then(
        (result) => {
          setPerson(result);
          setUpdatedPerson(result);
          setMode("view");
        },
        (error) => {
          console.log(error.message);
          setError(error.message);
          setMode("error");
        }
      );
  }

  const resetCard = () => {
    setPerson(null);
    setUpdatedPerson(null);
    setError(null);
    setMode("loading");
  }

  // RENDER
  if (mode === "loading") {
    return (
      <LoadingCard cardTitle="Dades personals" />
    );
  } else if (mode === "error") {
    return (
      <ErrorCard cardTitle="Dades personals" errorMessage={error} resetCard={resetCard} />
    );
  } else if (mode === 'create') {
    return (
      <Card>
        <CardHeader title={<Typography variant="h5" color="primary">Dades personals</Typography>}></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="first_name" name="first_name" label="Nom"
                value={ updatedPerson.first_name }
                onChange={handleInputChange}
                fullWidth variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="last_name" name="last_name" label="Cognoms"
                value={ updatedPerson.last_name }
                onChange={handleInputChange}
                fullWidth variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="dni" name="dni" label="DNI"
                value={ updatedPerson.dni }
                onChange={handleInputChange}
                fullWidth variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="gender-label">Gènere</InputLabel>
                <Select value={ updatedPerson.gender } name="gender" label="Gènere" onChange={handleInputChange}>
                  <MenuItem value={'U'}>-</MenuItem>
                  <MenuItem value={'F'}>Dona</MenuItem>
                  <MenuItem value={'M'}>Home</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Data naixement"
                onChange={ v => handleInputChange({ target: { name:"birth_date", value: defaultInternalDateFormat(v) }}) }></DatePicker>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <IconButton color="primary" aria-label="modificar" onClick={handleCreateSubmit}>
            <SaveIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  } else if (mode === "edit") {
    return (
      <Card>
        <CardHeader title={<Typography variant="h5" color="primary">Dades personals</Typography>}></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="first_name" name="first_name" label="Nom"
                value={ updatedPerson.first_name }
                onChange={handleInputChange}
                fullWidth variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="last_name" name="last_name" label="Cognoms"
                value={ updatedPerson.last_name }
                onChange={handleInputChange}
                fullWidth variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="dni" name="dni" label="DNI"
                value={ updatedPerson.dni }
                onChange={handleInputChange}
                fullWidth variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="gender-label">Gènere</InputLabel>
                <Select value={ updatedPerson.gender } name="gender" label="Gènere" onChange={handleInputChange}>
                  <MenuItem value={'U'}>-</MenuItem>
                  <MenuItem value={'F'}>Dona</MenuItem>
                  <MenuItem value={'M'}>Home</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Data naixement"
                value={dayjs(updatedPerson.birth_date)}
                onChange={ v => handleInputChange({ target: { name:"birth_date", value: defaultInternalDateFormat(v) }}) }></DatePicker>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <IconButton color="primary" aria-label="no-modificar" onClick={() => { setUpdatedPerson(person); setMode("view"); }}>
            <UndoIcon />
          </IconButton>
          <IconButton color="primary" aria-label="modificar" onClick={handleUpdateSubmit}>
            <SaveIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  } else {
    // VISUALITZACIO
    return (
      <Card>
        <CardHeader title={<Typography variant="h5" color="primary">Dades personals</Typography>}></CardHeader>
        <CardContent>
          <List>
            <ListItem alignItems="flex-start" divider>
              <ListItemText
                primary={<Typography variant="subtitle2" color="text.secondary">Nom</Typography>}
                secondary={<Typography variant="body1" color="text.primary">{ person.first_name }</Typography>}
              />
            </ListItem>
            <ListItem alignItems="flex-start" divider>
              <ListItemText
                primary={<Typography variant="subtitle2" color="text.secondary">Cognoms</Typography>}
                secondary={<Typography variant="body1" color="text.primary">{ person.last_name }</Typography>}
              />
            </ListItem>
            <ListItem alignItems="flex-start" divider>
              <ListItemText
                primary={<Typography variant="subtitle2" color="text.secondary">DNI</Typography>}
                secondary={<Typography variant="body1" color="text.primary">{ person.dni }</Typography>}
              />
            </ListItem>
            <ListItem alignItems="flex-start" divider>
              <ListItemText
                primary={<Typography variant="subtitle2" color="text.secondary">Gènere</Typography>}
                secondary={
                  <Typography variant="body1" color="text.primary">
                    { person.gender === 'M' ? 'Home' : person.gender === 'F' ? 'Dona' : '-' }
                  </Typography>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start" divider>
              <ListItemText
                primary={<Typography variant="subtitle2" color="text.secondary">Data naixement</Typography>}
                secondary={<Typography variant="body1" color="text.primary">{ defaultDisplayDateFormat(person.birth_date) }</Typography>}
              />
            </ListItem>
            { adminMode && (
              <>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography fontSize={"0.8rem"} variant="subtitle2" color="text.secondary">Id de persona</Typography>}
                    secondary={<Typography fontSize={"0.8rem"} variant="body1" color="text.primary">{ person.id }</Typography>}
                  />
                </ListItem>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography fontSize={"0.8rem"} variant="subtitle2" color="text.secondary">Data creació registre</Typography>}
                    secondary={<Typography fontSize={"0.8rem"} variant="body1" color="text.primary">{ person.created }</Typography>}
                  />
                </ListItem>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography fontSize={"0.8rem"} variant="subtitle2" color="text.secondary">Data actualització registre</Typography>}
                    secondary={<Typography fontSize={"0.8rem"} variant="body1" color="text.primary">{ person.modified }</Typography>}
                  />
                </ListItem>
              </>
            )}
          </List>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <IconButton color="primary" aria-label="modificar" onClick={() => setMode("edit")}>
            <EditIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  }
};
