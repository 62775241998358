import React from 'react';
// , { useEffect, useState } from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import { Grid } from '@mui/material';
//import UserInfo from '../components/UserInfo';
import { useParams } from 'react-router-dom';
//import { UserContext } from '../components/UserContext';
import UserInfoCard from '../cards/UserInfoCard';

export default function UserInfoPage() {

  const { userId } = useParams();
  const id = userId != null ? userId : "me";

  return (
    <DefaultLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <UserInfoCard userId={id} />
        </Grid>
      </Grid>
    </DefaultLayout>
  );

  // const { adminMode } = React.useContext(UserContext);

  // const [user, setUser] = useState(null);

  // const { userId } = useParams();
  // let id = userId != null ? userId : "me";

  // useEffect(() => {

  //   fetch(process.env.REACT_APP_API_DOMAIN + "/people/users/"+id+"/", {
  //     method:'GET',
  //     credentials: 'include'
  //   })
  //   .then(res => {
  //     if (!res.ok) {
  //       if ([401, 403].includes(res.status)) {
  //         throw Error('Not Authenticated');
  //       } else if ([401, 403].includes(res.status)) {
  //           throw Error('Not Found');
  //       } else {
  //         throw Error(res.statusText);
  //       }
  //     }
  //     return res.json()
  //   })
  //   .then(
  //     (result) => {
  //       //console.log(result);
  //       setUser(result);
  //     },
  //     (error) => {
  //       console.log(error.message);
  //     }
  //   );
  // }, [id]);

  // return (
  //   <DefaultLayout>
  //     <Grid container justifyContent="left" spacing={2}>
  //       {
  //         user != null && (
  //           <UserInfo user={user} detailed={adminMode}/>
  //         )
  //       }
  //     </Grid>
  //   </DefaultLayout>
  // );

};
