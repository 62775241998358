import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, Card, CardActions, CardContent, Collapse, IconButton, Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { defaultDisplayDateFormat } from '../utils/Utilities';


const MemberBadgeValidationCard = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [badge, setBadge] = useState(null);
  const [error, setError] = useState(null);
  const [collapseDetail, setCollapseDetail] = useState(false);

  const toggleCollapse = () => {
    setCollapseDetail(!collapseDetail);
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_API_DOMAIN + "/members/badges/" + props.badge_id.split(":")[0] + "/", {
      method:'GET', 
      credentials: 'include' 
    })
    .then(res => {
      if (!res.ok) {
        if ([401, 403].includes(res.status)) {
          throw Error('Not Authenticated');
        } else if ([404].includes(res.status)) {
          throw Error('Not Found');
        } else {
          throw Error(res.statusText);
        }
      }
      return res.json()
    })
    .then(
      (result) => {
        console.log(result);
        setBadge(result);
        setIsLoaded(true);
      },
      (error) => {
        console.log("ERROR: "+error.message);
        setError(error);
        setIsLoaded(true);
      }
    );
  }, [props]);

  if (isLoaded) {

    if (error === null) {

      var validationResult = '???';

      if (badge != null) {
        if (!badge.member.active) {
          validationResult = 'nok';
        } else if (Date.parse(badge.badge_expiration_date) < new Date()) {
          validationResult = 'warning';
        } else {
          validationResult = 'ok';
        }
      }

      return (
        <Card>
          <CardContent>
            <Box borderRadius={1} padding={3} marginBottom={2}
                bgcolor={validationResult === 'ok' ? "success.main" : validationResult === 'nok' ? "error.main" : "warning.main" }
                color={"white"}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography variant="h5" component="div">
                  { badge.member.person.user.first_name + ' ' + badge.member.person.user.last_name 
                      + " (" + badge.member.member_number + ")" }
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography sx={{ mb: 1.5 }} variant={'caption'}>
                  { badge.id }
                </Typography>
              </Box>
            </Box>
            <Collapse in={collapseDetail}>
              <List>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography variant="subtitle2" color="text.secondary">Membre actiu</Typography>}
                    secondary={<Typography variant="body1" color="text.primary">{ badge.member.active ? 'Sí' : 'No' }</Typography>}
                  />
                </ListItem>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography variant="subtitle2" color="text.secondary">Data alta</Typography>}
                    secondary={<Typography variant="body1" color="text.primary">{ defaultDisplayDateFormat(badge.member.activation_date) }</Typography>}
                  />
                </ListItem>
                { badge.member.deactivation_date != null && (
                  <ListItem alignItems="flex-start" divider>
                    <ListItemText
                      primary={<Typography variant="subtitle2" color="text.secondary">Data baixa</Typography>}
                      secondary={<Typography variant="body1" color="text.primary">{ badge.member.deactivation_date ? defaultDisplayDateFormat(badge.member.deactivation_date) : "-" }</Typography>}
                    />
                  </ListItem>
                )}
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography variant="subtitle2" color="text.secondary">Tipus de soci</Typography>}
                    secondary={<Typography variant="body1" color="text.primary">{ badge.member.subscription_type_display }</Typography>}
                  />
                </ListItem>
                <ListItem alignItems="flex-start" divider>
                  <ListItemText
                    primary={<Typography variant="subtitle2" color="text.secondary">Data caducitat carnet</Typography>}
                    secondary={<Typography variant="body1" color="text.primary">{ defaultDisplayDateFormat(badge.badge_expiration_date) }</Typography>}
                  />
                </ListItem>
              </List>
            </Collapse>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <IconButton color="primary" aria-label="expandir detalls" onClick={toggleCollapse}>
              { collapseDetail ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
            </IconButton>
            { collapseDetail && (
              <IconButton color="primary" aria-label="detall soci" component={Link} to={ "/members/" + badge.member.id }>
                <AccountCircleIcon />
              </IconButton>
            )}
            <IconButton color="primary" aria-label="recarregar" onClick={props.resetScan}>
              <ReplayIcon />
            </IconButton>
          </CardActions>
        </Card>
      )
    } else {
      return (
        <Card>
          <CardContent>{error}</CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <IconButton color="primary" aria-label="recarregar" onClick={props.resetScan}>
              <ReplayIcon />
            </IconButton>
          </CardActions>
        </Card>
      )
    }
  } else {
    return (
      <div>Carregant dades...</div>
    )
  }

};

export default MemberBadgeValidationCard;