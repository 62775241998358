import React, { useState, useEffect } from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { defaultDisplayDateFormat } from '../utils/Utilities';
import { Grid } from '@mui/material';

export default function MemberBadgeListPage() {
  
  const [badges, setBadges] = useState([]);
  
  useEffect(() => {
    fetch(process.env.REACT_APP_API_DOMAIN + "/members/badges/", {
      method:'GET',
      credentials: 'include' 
    })
    .then(res => {
      if (!res.ok) {
        if ([401, 403].includes(res.status)) {
          throw Error('Not Authenticated');
        } else {
          throw Error(res.statusText);
        }
      }
      return res.json()
    })
    .then(
      (result) => {
        setBadges(result.results);
      },
      (error) => {
        console.log(error.message);
      }
    );
  }, []);
    
  const columns = [
    { field: 'actions', type: 'actions', width: 30,
      getActions: (params) => [
        <GridActionsCellItem
        component={Link} to={'/badges/'+params.row.id}
        icon={<VisibilityIcon/>} label="Veure carnet"
        showInMenu={false} />,
      ]
    },
    { field: 'id', headerName: 'ID' },
    {
      field: 'full_name',
      headerName: "Nom",
      width: 200,
      valueGetter: (params) => {
        console.log({ params });
        let result = [];
        if (params.row.member && params.row.member.person && params.row.member.person.user) {
          if (params.row.member.person.user.first_name) {
            result.push(params.row.member.person.user.first_name);
          }
          if (params.row.member.person.user.last_name) {
            result.push(params.row.member.person.user.last_name);
          }
        } else {
          result = ["Unknown"];
        }
        return result.join(" ");
      }
    },
    { field: 'member_number',
      headerName: 'Núm. de soci', width: 80, type: 'number',
      valueGetter: (params) => {
        return params.row.member.member_number;
      }
    },
    { field: 'created', type: 'date', headerName: 'Data Alta', width: 120,
      valueFormatter: params => { 
        return (params.value !== null)? defaultDisplayDateFormat(params.value) : null
      }
    },
    { field: 'badge_expiration_date', type: 'date', headerName: 'Data Caducitat', width: 120,
      valueFormatter: params => { 
        return (params.value !== null)? defaultDisplayDateFormat(params.value) : null
      }
    },
  ]

  return (
    <DefaultLayout>
      <Grid item xs={12}>
        <DataGrid rows={badges} columns={columns} autoHeight
          initialState={{
            sorting: {
              sortModel: [{ field: 'member_number', sort: 'asc' }],
            },
          }}
        />
      </Grid>
    </DefaultLayout>
  );
};
