import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import UndoIcon from '@mui/icons-material/Undo';
import { Card, CardActions, CardContent, ClickAwayListener, Collapse, Grid, IconButton, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { phoneService } from '../services/PhoneService';
import ErrorCard from './ErrorCard';
import LoadingCard from './LoadingCard';

export default function ContactPhoneItem({ phoneId, personId, resetParent }) {

  const [phone, setPhone] = useState(null);
  const [updatedPhone, setUpdatedPhone] = useState(null);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState("loading");
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (phoneId != null) {
      phoneService.getById(phoneId)
        .then(
          (result) => {
            setPhone(result);
            setUpdatedPhone(result);
            console.log(result);
            if (mode === "loading") {
              setMode("view");
            }
          },
          (error) => {
            console.log(error);
            setError(error);
            setMode("error");
          }
        );
    } else if (mode === "loading") {
      setMode("view");
      setUpdatedPhone(
        {
          number: '',
          description: '',
          primary: false
        }
      )
    }
  }, [phoneId, mode]);

  const togglePreferred = () => {

    phoneService.update(phone.id, { ...phone , primary: true })
    .then(
      (result) => {
        console.log(result);
      },
      (error) => {
        console.log(error);
      }
    );
    resetParent();
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUpdatedPhone({ ...updatedPhone, [name]: value });
  }

  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    phoneService
      .update(phone.id, updatedPhone)
      .then(
        (result) => {
          setPhone(result);
          setUpdatedPhone(result);
          setMode("view");
        },
        (error) => {
          console.log(error.message);
          setError(error.message);
          setMode("error");
        }
      );
  }

  const handleCreateSubmit = (event) => {
    event.preventDefault();
    phoneService
      .create({ ...updatedPhone, person_id: personId })
      .then(
        (result) => {
          setPhone(result);
          setUpdatedPhone(result);
          setMode("view");
          resetParent();
        },
        (error) => {
          console.log(error.message);
          setError(error.message);
          setMode("error");
        }
      );
  }

  const handleDeleteSubmit = (event) => {
    event.preventDefault();
    phoneService
      .delete(updatedPhone.id)
      .then(
        (result) => {
          setPhone(result);
          setUpdatedPhone(result);
          setMode("view");
          resetParent();
        },
        (error) => {
          console.log(error.message);
          setError(error.message);
          setMode("error");
        }
      );
  }

  const resetCard = () => {
    setPhone(null);
    setUpdatedPhone(null);
    setError(null);
    setMode("loading");
    setExpanded(false);
  }

  // RENDER
  if (mode === "loading") {
    return (
      <LoadingCard/>
    );
  } else if (mode === "error") {
    return (
      <ErrorCard errorMessage={error} resetCard={resetCard} />
    );
  } else if (mode === 'create') {
    return (
      <ClickAwayListener onClickAway={ (e) => { e.stopPropagation(); setExpanded(false); setMode('view') }}>
        <Card raised={true}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="number" name="number" label="Número de telèfon"
                  value={ updatedPhone.number }
                  onChange={handleInputChange}
                  fullWidth variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description" name="description" label="Descripció"
                  value={ updatedPhone.description }
                  onChange={handleInputChange}
                  fullWidth variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <IconButton color="primary" aria-label="no-modificar" onClick={resetCard}>
              <UndoIcon />
            </IconButton>
            <IconButton color="primary" aria-label="crear" onClick={handleCreateSubmit}>
              <SaveIcon />
            </IconButton>
          </CardActions>
        </Card>
      </ClickAwayListener>
    );
  } else if (mode === "edit") {
    return (
      <ClickAwayListener onClickAway={ (e) => { e.stopPropagation(); setExpanded(false); setMode('view') }}>
        <Card raised={expanded}>
          <CardContent>
            <Grid container mt={1} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="number" name="number" label="Número de telèfon"
                  value={ updatedPhone.number }
                  onChange={handleInputChange}
                  fullWidth variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description" name="description" label="Descripció"
                  value={ updatedPhone.description }
                  onChange={handleInputChange}
                  fullWidth variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <IconButton color="primary" aria-label="no-modificar" onClick={resetCard}>
              <UndoIcon />
            </IconButton>
            <IconButton color="primary" aria-label="modificar" onClick={handleUpdateSubmit}>
              <SaveIcon />
            </IconButton>
          </CardActions>
        </Card>
      </ClickAwayListener>
    );
  } else {
    if (phone === null) {
      // VISUALITZACIO BUIT
      return (
        <Card>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <IconButton color="primary" aria-label="afegir" onClick={() => setMode("create")}>
              <AddCircleOutlineIcon />
            </IconButton>
          </CardActions>
        </Card>
      )  
    } else {
      // VISUALITZACIO AMB DADES
      return (
        <ClickAwayListener onClickAway={ (e) => { e.stopPropagation(); setExpanded(false); }}>
          <Card raised={expanded}>
            <CardContent onClick={handleExpandClick}>
              <Typography noWrap variant="body1" color="text.primary">{ phone.number }</Typography>
            </CardContent>
            <Collapse in={expanded} unmountOnExit>
              { phone.description !== null && phone.description.length > 0 && 
                <CardContent>
                  <Typography noWrap variant="body2" color="text.secundary">{ phone.description }</Typography>
                </CardContent>
              }
            </Collapse>
            { expanded && (
              <CardActions sx={{ justifyContent: "space-between" }}>
                <Stack direction="row">
                  <IconButton color="primary" aria-label="modificar" onClick={ togglePreferred }>
                    { phone.primary? <StarIcon /> : <StarBorderIcon /> }
                  </IconButton>
                </Stack>
                <Stack direction="row">
                  { expanded && ( 
                    <IconButton color="primary" aria-label="modificar" onClick={ () => setMode("edit") }>
                        <EditIcon />
                    </IconButton>
                  )}
                  { expanded && !phone.primary && (
                    <IconButton color="primary" aria-label="modificar" onClick={handleDeleteSubmit}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <IconButton color="primary" aria-expanded={expanded} aria-label="show more" onClick={handleExpandClick}>
                    { expanded? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                  </IconButton>
                </Stack>
              </CardActions>
            )}
          </Card>
        </ClickAwayListener>
      );
    }
  }
};
