import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import { Grid } from '@mui/material';
import PersonInfoCard from '../cards/PersonInfoCard';
import UserInfoCard from '../cards/UserInfoCard';
import ContactPhonesCard from '../cards/ContactPhonesCard';
import ContactEmailsCard from '../cards/ContactEmailsCard';

export default function ProfilePage() {

  return (
    <DefaultLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <UserInfoCard userId="me" />
        </Grid>
        <Grid item xs={12} md={6}>
          <PersonInfoCard personId="me" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactPhonesCard personId="me" />
        </Grid>
        <Grid item xs={12} md={6}>
          <ContactEmailsCard personId="me" />
        </Grid>

      </Grid>
    </DefaultLayout>
  );

};
