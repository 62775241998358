import ReplayIcon from '@mui/icons-material/Replay';
import { Card, CardActions, CardContent, CardHeader, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function ErrorCard({ cardTitle, errorMessage, resetCard }) {

  return (
    <Card>
      { cardTitle && <CardHeader title={<Typography variant="h5" color="primary">{ cardTitle }</Typography>}></CardHeader>}
      <CardContent><Typography variant="body1">ERROR {errorMessage}</Typography></CardContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <IconButton color="primary" aria-label="recarregar" onClick={resetCard}>
          <ReplayIcon />
        </IconButton>
      </CardActions>
    </Card>
  );

};
