import './App.css';
import React, { useState, useEffect } from 'react';
import { UserContext } from './components/UserContext';
import AppRouter from './AppRouter';
import { red, deepOrange } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ca';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: deepOrange[700] //'#be3455', //red[600], //'#d32f2f', //'#ad0a1f', //yellow[600],
    },
  },
});

const adminTheme = createTheme({
  palette: {
    primary: {
      main: red[800], //'#d32f2f', //'#ad0a1f', //yellow[600],
    },
  },
});

function App() {

  const [userError, setUserError] = useState(null);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [userData, setUserData] = useState({});
  const [isAdminMode, setIsAdminMode] = useState(!!JSON.parse(localStorage.getItem("adminMode-"+userData.id)));
  
  /*{
     username: 'enric',
     first_name: 'Enric',
     avatarImg: 'https://lh3.googleusercontent.com/-qCM6dzuam3g/AAAAAAAAAAI/AAAAAAAAAAA/AFi9ZuHAuylWVlcvuKKLKP_8fnDKDXAaAQ/photo.jpg',
     groups: [ { name: 'managers' }]
  });*/

  const toggleAdminMode = () => {
    localStorage.setItem("adminMode-"+userData.id, !isAdminMode);
    setIsAdminMode(!isAdminMode);
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_API_DOMAIN + "/people/users/me/", {
        method:'GET', 
        credentials: 'include' 
    })
    .then(res => {
      if (!res.ok) {
        if ([401, 403].includes(res.status)) {
          throw Error('Not Authenticated');
        } else {
          throw Error(res.statusText);
        }
      }
      return res.json()
    })
    .then(
      (result) => {
        setIsUserLoaded(true);
        setUserData(result);
        setIsAdminMode(JSON.parse(localStorage.getItem("adminMode-"+result.id)));
      },
      (error) => {
        setIsUserLoaded(true);
        setUserError(error);
      }
    )
  }, [])

  if (isUserLoaded) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ca">
        <UserContext.Provider value={{ user: userData, adminMode: isAdminMode, toggleAdminMode: toggleAdminMode }}>
          <ThemeProvider theme={ isAdminMode ? adminTheme : defaultTheme }>
              <AppRouter/>
          </ThemeProvider>
        </UserContext.Provider>
      </LocalizationProvider>
    );
  } else if (userError) {
    return (
      <div>{userError}</div>
    );
  } else {
    return (
      <div>Carregant dades...</div>
    );
  }
}

export default App;
