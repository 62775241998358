import MenuIcon from '@mui/icons-material/Menu';  
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React from 'react';

export default function AnonUserAppBar(props) {

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Hola!
        </Typography>

        <IconButton
          size="large"
          aria-label="context menu of anonymous user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={props.handleMenu}
          color="inherit"
        >
          <MenuIcon/>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
