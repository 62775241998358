import React, { useState } from 'react';
import QRCode from 'react-qr-code';
import DefaultLayout from '../layouts/DefaultLayout';

function QRShowPage() {

  const [qrText, setQrText] = useState('hey');

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  return (
      <DefaultLayout>

        <form onSubmit={handleSubmit}>
          <label>
            QR Text:
            <input type="text" value={qrText} onChange={e => setQrText(e.target.value)} />
          </label>
        </form>
        
        <div style={{ background: 'white', padding: '16px' }}>
          <QRCode value={qrText}/>
        </div>
        <br></br>
        <div style={{ background: 'white', padding: '16px' }}>
          <QRCode value="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" />
        </div>
        <br></br>
        {/* <QRScanner /> */}

      </DefaultLayout>

  );
}

export default QRShowPage;