import React, { useContext, useState } from 'react';
import { UserContext } from '../components/UserContext';
import { Navigate, useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import ConnectedUserAppBarMenu from '../components/ConnectedUserAppBarMenu';
import ConnectedUserAppBar from '../components/ConnectedUserAppBar';
import ConnectedUserDrawer from '../components/ConnectedUserDrawer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Grid, IconButton } from '@mui/material';

function DefaultLayout(props) {

  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [drawerState, setDrawerState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (event.target.classList.contains('MuiSwitch-input')) {
      return;
    }
    setDrawerState(open);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const scrollToTop = () => {
    window['scrollTo']({ top: 0, behavior: 'smooth' })
  }

  if (user != null && user.username != null && user.username !== "guest") {
    return (
      <>
        <ConnectedUserDrawer drawerState={drawerState} toggleDrawer={toggleDrawer} />

        <Box sx={{ flexGrow: 1 }}>
          <ConnectedUserAppBar toggleDrawer={toggleDrawer} handleMenu={handleMenu} />
        </Box>

        <ConnectedUserAppBarMenu anchorEl={anchorEl} handleMenuClose={handleMenuClose} />

        <main>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={2}>
                <Grid item xs={1} sm={1} md={2}/>
                <Grid item container spacing={2} xs={10} sm={10} md={8}>
                  {props.children}
                </Grid>
                <Grid item xs={1} sm={1} md={2}/>
                <Grid item xs={1} sm={1} md={2}/>
                <Grid item xs={10} sm={10} md={8}>
                  { !props.hideBackButton &&
                    <IconButton aria-label="back" color="primary" onClick={() => navigate(-1)}>
                      <ArrowBackIcon />
                    </IconButton>
                  }
                  { !props.hideToTopButton &&
                    <IconButton aria-label="to top" color="primary" onClick={scrollToTop}>
                      <ExpandLessIcon />
                    </IconButton>
                  }
                </Grid>
                <Grid item xs={1} sm={1} md={2}/>
              </Grid>
            </Container>
        </main>
      </>
    )
  } else {
      return <Navigate to="/" replace />;
  }
}

export default DefaultLayout;

