import React from 'react';
import AnonymousUserLayout from '../layouts/AnonymousUserLayout';

function LoginPage() {

  return (
    <AnonymousUserLayout>
      <div>
        <a href={process.env.REACT_APP_API_DOMAIN + "/social/login/google-oauth2/"}>
          <div className="google-signin-btn"></div>
        </a>
      </div>
    </AnonymousUserLayout>
  );
}

export default LoginPage;