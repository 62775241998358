import { Card, CardContent, CardHeader, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { peopleService } from '../services/PeopleService';
import ContactPhoneItem from './ContactPhoneItem';
import ErrorCard from './ErrorCard';
import LoadingCard from './LoadingCard';

export default function ContactPhonesCard({ personId = "me" }) {

  const [person, setPerson] = useState(null);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState("loading");

  useEffect(() => {
    peopleService.getById(personId)
      .then(
        (result) => {
          setPerson(result);
          console.log(result);
          if (mode === "loading") {
            setMode("view");
          }
        },
        (error) => {
          console.log(error);
          setError(error);
          setMode("error");
        }
      );
  }, [personId, mode]);

  const resetCard = () => {
    setPerson(null);
    setError(null);
    setMode("loading");
  }

  // RENDER
  if (mode === "loading") {
    return (
      <LoadingCard cardTitle="Telèfons de contacte" />
    );
  } else if (mode === "error") {
    if (error === "Not Found") {
      return null;
    } else {
      return (
        <ErrorCard cardTitle="Telèfons de contacte" errorMessage={error} resetCard={resetCard} />
      );
    }
  } else {
    // VISUALITZACIO
    return (
      <Card>
        <CardHeader title={<Typography variant="h5" color="primary">Telèfons de contacte</Typography>} onClick={resetCard}></CardHeader>
        <CardContent>
          <Grid container spacing={2}>
            { 
              person.phones.map((phone) => 
                <Grid item xs={12}>
                  <ContactPhoneItem phoneId={ phone.id } personId={ person.id } resetParent={resetCard} />
                </Grid>
              )
            }
            <Grid item xs={12}>
              <ContactPhoneItem phoneId={ null } personId={ person.id } resetParent={resetCard} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
};
