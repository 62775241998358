import React, { useState, useEffect } from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid } from '@mui/material';

export default function UserInfoListPage() {

   const [users, setUsers] = useState([]);

   useEffect(() => {
      fetch(process.env.REACT_APP_API_DOMAIN + "/people/users/", {
          method:'GET', 
          credentials: 'include' 
      })
      .then(res => {
         if (!res.ok) {
           if ([401, 403].includes(res.status)) {
             throw Error('Not Authenticated');
           } else {
             throw Error(res.statusText);
           }
         }
         return res.json()
       })
       .then(
         (result) => {
             setUsers(result.results);
         },
         (error) => {
           console.log(error.message);
         }
       );
   }, []);

   const columns = [
      { field: 'actions', headerName: 'Accions', type: 'actions', width: 30,
         getActions: (params) => [
            <GridActionsCellItem
               component={Link} to={'/users/'+params.row.id}
               icon={<VisibilityIcon/>} label="Veure usuari"
               showInMenu={false} />,
         ]
      },
      { field: 'id', headerName: 'ID', type: 'number', width: 50 },
      { field: 'last_name', headerName: 'Cognoms', width: 180 },
      { field: 'first_name', headerName: 'Nom', width: 120 },
      { field: 'username', headerName: 'Usuari', width: 120 },
      { field: 'email', headerName: 'Email', width: 280 },
   ]

   return (
      <DefaultLayout>
         <Grid item xs={12}>
            <DataGrid rows={users} columns={columns} autoHeight 
               initialState={{
                  sorting: {
                     sortModel: [{ field: 'id', sort: 'asc' }],
                  },
               }}
            />
         </Grid>
      </DefaultLayout>
   );
};
