import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { UserContext } from './UserContext';
import { useContext } from 'react';

export default function ConnectedUserAppBar(props) {

  const { user } = useContext(UserContext);

  // console.log(user);

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={props.toggleDrawer(true)}
        >
          <MenuIcon/>
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Hola!
        </Typography>

        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={props.handleMenu}
          color="inherit"
        >
          <Avatar
            alt={user.username}
            src={user.picture}
            referrerPolicy="no-referrer" >
          </Avatar>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
