import { getCookie } from "./Utilities";

export const fetchWrapper = {
    get,
    post,
    put,
    delete: _delete
};

async function get(url) {
    const requestOptions = {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json'
        }
    };
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
}

async function post(url, body) {
    const requestOptions = {
        method: 'POST',
        credentials: 'include',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json' ,
            'X-CSRFToken': getCookie('csrftoken')
        },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

async function put(url, body) {
    const requestOptions = {
        method: 'PUT',
        credentials: 'include',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json' ,
            'X-CSRFToken': getCookie('csrftoken')
        },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);    
}

// prefixed with underscored because delete is a reserved word in javascript
async function _delete(url) {
    const requestOptions = {
        method: 'DELETE',
        credentials: 'include',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json' ,
            'X-CSRFToken': getCookie('csrftoken')
        }
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

async function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        //console.log("response", data);
        
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            console.log("error", error);
            return Promise.reject(error);
        }

        return data;
    });
}
