import React from 'react';

export const UserContext = React.createContext({
  user: {
    username: 'guest',
    first_name: 'Guest',
  },
  adminMode: false,
  toggleAdminMode: () => {}
});
