import React from 'react';
import QRScanner from '../components/QRScanner';
import DefaultLayout from '../layouts/DefaultLayout';

function QRReaderPage() {

  return (
      <DefaultLayout>
        <QRScanner />
      </DefaultLayout>
  );
}

export default QRReaderPage;